import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React, { Component } from 'react';
import MainPage from "./components/pages/MainPage";
import './components/styles/App.css';
import './components/styles/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from "./components/context/user-context";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <MainPage />
      </AuthProvider>
    </div>
  );
}

export default App;
