import React, { useState } from 'react';
import { BrowserRouter as Router, HashRouter, Switch, Route, Link } from "react-router-dom";
import useToken from '../useToken';
import ReserveTable from '../widgets/items/ReserveTable';
import MainHeader from '../widgets/main/MainHeader';
import MainNavbar from '../widgets/main/MainNavbar';
import ItemsPage from './ItemsPage';
import Login from './Login';
import QueryPage from './QueryPage';
import ReservePage from './ReservePage';
import ScrapPage from './ScrapPage';
import AddItemPage from './sub/AddItemPage';
import EditUserPage from "./sub/AddUserPage";
import UsersPage from './UsersPage';
import LockerPage from './LockerPage';


function MainPage() {
  const { token, setToken } = useToken();

    if(!token) {
      return <Login setToken={setToken} />
    }
    return (
    <HashRouter >
         <Switch>
          <Route path="/users">
            <UsersPage />
          </Route>
          <Route path="/reserve">
            <ReservePage />
          </Route>
          <Route path="/scrap">
            <ScrapPage />
          </Route>
          <Route path="/query">
            <QueryPage />
          </Route>
          <Route path="/locker">
            <LockerPage />
          </Route>
          <Route path="/adduser">
            <EditUserPage />
          </Route>
          <Route path="/additem">
            <AddItemPage />
          </Route>
          <Route path="/login">
            <Login  setToken={setToken} />
          </Route>
          <Route path="/">
            <ItemsPage />
          </Route>
        </Switch>
    </HashRouter>
    )
}

export default MainPage
