import React, { useState, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';
import { Button } from 'react-bootstrap'
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import MainButton from '../main/MainButton';
import useAuth from '../../context/user-context';
import Stepper from '../main/Stepper';

const rows = [
  { id: 1, name: '', email: '', level: '' },
];

function UserTable() {
  const token = localStorage.getItem('token');
  const userToken = JSON.parse(token);
  const [data, setData] = useState(null);
  const [text, setText] = useState(null);
  const { savedUPage, setUPageIndex } = useAuth();

  const fetchUsers = async (text) => {
    fetch("https://targyi-eszkoz.appsolution.hu:3001/users/admin/search", {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        'text': text ? text : ''
      }),
      headers: { '500Token': userToken.token, 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => setData(data.data));
  }


  const serachType = () => {
    const text = document.getElementById("searchbar").value;
    setText(text);
    fetchUsers(text);
  };

  useEffect(() => {
    fetchUsers()
    console.log(data);
  }, [])

  const handleDelete = (id) => {
    fetch("https://targyi-eszkoz.appsolution.hu:3001/users/" + id, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      headers: { '500Token': userToken.token },
    }).then((val) => fetchUsers());

  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Név', width: 200 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'levelName', headerName: 'Jogosultság', width: 200 },
    {
      field: 'action', headerName: 'Művelet', width: 150, renderCell: (params) => {
        return (
          <div className='action-buttons'>
            <Link style={{ textDecoration: 'none' }} to={{
              pathname: "/adduser",
              state: {
                getLocation: params.row,
              },
            }}>
              <div className='action-btn'>Módosít</div>

            </Link>
            <IconButton aria-label="primary" variant="outlined" color="secondary">
              <DeleteIcon onClick={() => handleDelete(params.row.id)} />
            </IconButton>
          </div>
        )
      }
    },
    { field: 'leveId', headerName: '', hide: true },
  ];


  const stepPage = (val, full = false) => {
    if (data.length / 10 == 1) {
      return;
    }
    if (full) {
      if (val) {
        const end = Math.floor((data.length / 10));
        setUPageIndex(end);
      } else {
        setUPageIndex(0);
      }
      return;
    }
    if (val) {
      if (savedUPage < Math.floor((data.length / 10))) {
        setUPageIndex(savedUPage + 1);
      }
    } else {
      if (savedUPage - 1 >= 0) {
        setUPageIndex(savedUPage - 1);
      }
    }
  }

  return (
    <div>
      <div className='table-row'>
        <input type="text" name="search" id='searchbar' className='searchbar' onChange={() => serachType()} placeholder="Keresés.."></input>
        <Stepper length={!data ? 1 : data.length} onTap={stepPage} index={savedUPage} text='felhasználó' />
      </div>
      <div className='user-table col-12'>
        <div style={{ height: 650, width: '100%' }}>
          <DataGrid rows={!data ? rows : data} columns={columns} 
           page={savedUPage}
           options={{
             initialPage: 5
           }}
          pageSize={10} hideFooter disableSelectionOnClick />
        </div>
      </div>
    </div>
  )
}

export default UserTable
