import { Alert } from 'bootstrap'
import React from 'react'
import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import MainButton from '../widgets/main/MainButton';
import PageHeader from '../widgets/main/PageHeader';
import TopHeader from '../widgets/main/TopHeader';
import UserTable from '../widgets/users/UserTable';

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}


function UsersPage() {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
  }, [isLoading]);

  const handleClick = () => setLoading(true);

  return (
    <>
      <TopHeader />
      <div className='page'>
        <div className='user-page-header'>
          <PageHeader title='Felhasználók' />
          <Link style={{ textDecoration: 'none' }} to={{
            pathname: "/adduser",
            state: {
              getLocation: [],
            },
          }}>
            <MainButton name='Új felhasználó'/>
          </Link>
        </div>
        <UserTable />
      </div>
    </>
  )
}

export default UsersPage
