import { Button } from 'react-bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'
import ItemsTable from '../widgets/items/ItemsTable'
import PageHeader from '../widgets/main/PageHeader'
import TopHeader from '../widgets/main/TopHeader'
import MainButton from '../widgets/main/MainButton'
import LockerTable from '../widgets/items/LockerTable'

function LockerPage() {
  return (
    <>
      <TopHeader />
      <div className='page'>
        <div className='user-page-header'>
          <PageHeader title='Szekrények' />
          
        </div>
        <LockerTable />
      </div>
    </>
  )
}

export default LockerPage
