import { Button } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import PageHeader from '../../widgets/main/PageHeader'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Redirect } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom"
import TopHeader from '../../widgets/main/TopHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import Popup from 'reactjs-popup';

function AddItemPage() {
  let defDate;
  const loc = useLocation();
  const { getLocation } = loc.state == undefined ? [] : loc.state;
  const isNew = getLocation.length == 0 ? true : false;
  const token = localStorage.getItem('token');
  const userToken = JSON.parse(token);
  let history = useHistory()
  const [name, setName] = useState(isNew ? '' : getLocation.name);
  const [item, setItem] = useState(isNew ? '' : getLocation.item)
  const [itemSec, setItemSec] = useState(isNew ? '' : getLocation.itemSec)
  const [date, setDate] = useState(isNew ? '' : getLocation.dateOfPurchase)
  const [receipt, setReceipt] = useState(isNew ? '' : getLocation.receipt)
  const [location, setLocation] = useState(isNew ? '' : getLocation.location)
  const [account, setAccount] = useState(isNew ? '' : getLocation.account)
  const [comment, setComment] = useState(isNew ? '' : getLocation.comment)
  const [reserve, setReserve] = useState(isNew ? false : getLocation.reserve == 0 ? false : true);
  const [scrap, setScrap] = useState(isNew ? false : getLocation.scrap == 0 ? false : true);
  const [file, setFile] = useState(isNew ? '' : '')
  const [gFile, setgFile] = useState(isNew ? '' : '')
  const [rpath, setPath] = useState(isNew ? '' : getLocation.receiptPath)
  const [gpath, setgPath] = useState(isNew ? '' : getLocation.guaranteePath)
  const [category, setCategory] = useState();
  const [user, setUser] = useState();
  const [userData, setUserData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [guaranteeStart, setguaranteeStart] = useState(isNew ? '' : getLocation.guaranteeStart);
  const [guaranteeEnd, setguaranteeEnd] = useState(isNew ? '' : getLocation.guaranteeEnd);

  const fetchUsers = async () => {
    fetch("https://targyi-eszkoz.appsolution.hu:3001/items/get/admin", {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      headers: { '500Token': userToken.token },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryData(data.data.category);
        setUserData(data.data.users)
        if (isNew) {
          const catId = data.data.category[0].id;
          setCategory(catId);
        } else {
          const catId = data.data.category.filter(a => a.id == getLocation.categoryId)
          const id = catId[0].id;
          setCategory(id);
        }
        if (isNew) {
          const userId = data.data.users[0].id;
          setUser(userId);
        } else {
          const userId = data.data.users.filter(a => a.id == getLocation.userId)
          const id = userId[0].id;
          setUser(id);

        }
      });
  }

  useEffect(() => {
    fetchUsers();
    if (!isNew) {
      var temp = getLocation.dateOfPurchase.split('T');
      defDate = temp[0];
    }

  }, [])

  const save = (event) => {

    var formData = new FormData();
    const reserved = reserve ? 1 : 0;
    const scraped = scrap ? 1 : 0;
    formData.append('reserve', reserved);
    formData.append('scrap', scraped);
    formData.append('name', name);
    formData.append('categoryId', category);
    formData.append('item', item);
    formData.append('itemSec', itemSec);
    if (!reserve) {
      formData.append('userId', user);
    }
    formData.append('dateOfPurchase', date);
    formData.append('receipt', receipt);
    formData.append('location', location);
    formData.append('account', account);
    formData.append('comment', comment);
    formData.append('guaranteeStart', guaranteeStart);
    formData.append('guaranteeEnd', guaranteeEnd);
    formData.append('file', file);
    formData.append('gfile', gFile);
    let url;
    if (isNew) {
       url = 'https://targyi-eszkoz.appsolution.hu:3001/items';
      //url = 'http://127.0.0.1:3002/items';
    } else {
        url = 'https://targyi-eszkoz.appsolution.hu:3001/items/' + getLocation.uuid;
     // url = 'http://127.0.0.1:3002/items/' + getLocation.uuid;
    }
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: formData,
      headers: { '500Token': userToken.token },
    }).then((res) => res.json()).then((data) => {
      console.log(data.message);
      if (data.message == 'success') {
        history.push("/items")
      }
    });
    event.preventDefault();
  }
  const handleUser = (event) => {
    const temp = event.target.value;
    setUser(temp);
  };

  const handleCat = (event) => {
    const temp = event.target.value;
    setCategory(temp);
  };

  const handleFile = (event) => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  };
  const handlegFile = (event) => {
    console.log(event.target.files[0]);
    setgFile(event.target.files[0]);
  };
  const handleDelete = (id) => {
    setReceipt(null);
    fetch("https://targyi-eszkoz.appsolution.hu:3001/items/invoice/" + getLocation.uuid, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      headers: { '500Token': userToken.token },
    }).then((val) => { });
  }
  const handlegDelete = (id) => {
    setReceipt(null);
    setgPath(null);
    fetch("https://targyi-eszkoz.appsolution.hu:3001/items/guarantee/" + getLocation.uuid, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      headers: { '500Token': userToken.token },
    }).then((val) => { });
  }


  const setReserveVal = () => {
    if (reserve) {
      setReserve(false);
    } else {
      setReserve(true);
    }
  }
  const setScrapVal = () => {
    if (scrap) {
      setScrap(false);
    } else {
      setScrap(true);
    }
  }



  return (
    <>
      <style type="text/css">
        {`
        .btn-flat {
          color: #a344ec;
          border: #a344ec 1px solid;
        }
    
        .btn-flat:hover {
            background-color: #a344ec;
            color: white;
          }
       
        `}
      </style>
      <TopHeader />
      <div className='page'>
        <PageHeader title={isNew ? 'Eszköz hozzáadása' : 'Eszköz szerkesztése'} />

        <form id="form1" enctype="multipart/form-data" onSubmit={save}>
          <div className='edit-user-container box'>
            <div className='edit-user-form'>
              <div>
                <label><input type="checkbox" id="deleted" onChange={setReserveVal} value={reserve} checked={reserve} name="deleted"></input>
                  <span className='query-checkbox-text query-deleted'>Tartalék</span></label>
                <label className='c-padding' ><input className='c-padding' type="checkbox" id="deleted" onChange={setScrapVal} value={scrap} checked={scrap} name="deleted"></input>
                  <span className='query-checkbox-text query-deleted'>Selejt</span></label>
              </div>
              <label className='input-label'>Kategória</label>
              <FormControl className='edit-user-input'>
                <NativeSelect
                  value={!categoryData ? null : category}
                  name="cat"
                  onChange={handleCat}
                  inputProps={{ 'aria-label': 'level' }}>
                  {!categoryData ? <option key='0'></option> : categoryData.map((cat) =>
                    <option key={cat.id} value={cat.id}>{cat.name}</option>)}
                </NativeSelect>
              </FormControl>
              <label className='input-label'>Megnevezés</label>
              <input onChange={event => setName(event.target.value)} value={name} className='edit-user-input' type='text' required={!reserve}></input>
              <label className='input-label'>Termék azonosító 1</label>
              <input onChange={event => setItem(event.target.value)} value={item} className='edit-user-input' type='text'></input>
              <label className='input-label'>Termék azonosító 2</label>
              <input onChange={event => setItemSec(event.target.value)} value={itemSec} className='edit-user-input' type='text' ></input>
              <label className='input-label'>Könyvelési az.</label>
              <input onChange={event => setAccount(event.target.value)} value={account} className='edit-user-input' type='text'></input>
              <label className='input-label'>Vásárlás dátuma</label>
              <input onChange={event => setDate(event.target.value)} value={date} className='edit-user-input' type='text'></input>
              <label className='input-label'>AS számla sorszáma</label>
              <input onChange={event => setReceipt(event.target.value)} value={receipt} className='edit-user-input' type='text' required={!reserve}></input>
              <label className='input-label'>Hely</label>
              <input onChange={event => setLocation(event.target.value)} value={location} className='edit-user-input' type='text'></input>
            </div>
            <div className='edit-user-form'>
              {reserve ? <div></div> :
                <div>
                  <label className='input-label'>Felhasználó</label>
                  <div>

                    <FormControl className='edit-user-input'>
                      <NativeSelect
                        value={!userData ? null : user}
                        name="user"
                        onChange={handleUser}
                        inputProps={{ 'aria-label': 'level' }}>
                        {!userData ? <option key='0'></option> : userData.map((user) =>
                          <option key={user.id} value={user.id}>{user.name}</option>)}
                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>
              }
              <label className='input-label'>Megjegyzés</label>
              <input onChange={event => setComment(event.target.value)} value={comment} className='edit-user-input' type='text'></input>
              <label className='input-label'>Garancia</label>
              <div>
                <label className='subinput-label'>Dátumtól:</label>
                <input onChange={event => setguaranteeStart(event.target.value)} value={guaranteeStart} className='subedit-user-input' type='text'></input>
                <label className='subinput-label'>Dátumig:</label>
                <input onChange={event => setguaranteeEnd(event.target.value)} value={guaranteeEnd} className='subedit-user-input' type='text'></input>
              </div>
              <label className='input-label'>Számla</label>
              <input name="file" onChange={handleFile} type="file" />
              {rpath ? <p>Feltöltve: {rpath}</p> : <div></div>}
              <label className='input-label'>Garanciajegy</label>
              <input name="file" onChange={handlegFile} type="file" />
              {gpath ? <p>Feltöltve: {gpath}</p> : <div></div>}
              <Popup trigger={<Button className='edit-user-button' variant="flat">Mentés</Button>} modal>
                {close => (
                  <div className="popup box">
                    Biztos mented?
                    <div className='popup-row'>
                      <Button type="submit" form="form1" value="Submit" className='edit-user-button' variant="flat">Mentés</Button>
                      <div className='asdasd'></div>
                      <Button onClick={() => {
                        console.log('modal closed ');
                        close();
                      }} className='edit-user-button edit-user-button-red' variant="flat">Mégsem</Button>
                    </div>
                  </div>
                )}
              </Popup>
            </div>
            {getLocation.receiptPath && receipt ?
              <div className='tem-asd'>
                <label className='input-label'>Számla</label>
                <embed src={'https://targyi-eszkoz.appsolution.hu/api/public/invoices/' + getLocation.receiptPath} width="450px" height="450px" />
                <div className='pdf-row'>
                  <a target="_blank" className='pdf-btn' href={'https://targyi-eszkoz.appsolution.hu/api/public/invoices/' + getLocation.receiptPath}>
                    <Button className='' variant="flat">Megnyitás</Button>
                  </a>
                  <IconButton aria-label="primary" variant="outlined" color="secondary">
                    <DeleteIcon onClick={() => handleDelete()} />
                  </IconButton>
                </div>
              </div>
              : <div></div>}
            {getLocation.guaranteePath && gpath ?
              <div className='tem-asd'>
                <label className='input-label'>Garanciajegy</label>
                <embed src={'https://targyi-eszkoz.appsolution.hu/api/public/guarantees/' + gpath} width="450px" height="450px" />
                <div className='pdf-row'>
                  <a target="_blank" className='pdf-btn' href={'https://targyi-eszkoz.appsolution.hu/api/public/guarantees/' + getLocation.gpath}>
                    <Button className='' variant="flat">Megnyitás</Button>
                  </a>
                  <IconButton aria-label="primary" variant="outlined" color="secondary">
                    <DeleteIcon onClick={() => handlegDelete()} />
                  </IconButton>
                </div>
              </div>
              : <div></div>}
          </div>
        </form >
      </div >
    </>
  )
}

export default AddItemPage
