import React from 'react'

function MainButton({name, onClick}) {
    return (
        <div className='main-btn'onClick={onClick}>
            {name}
        </div>
    )
}

export default MainButton
