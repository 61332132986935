import { Button } from 'react-bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'
import ItemsTable from '../widgets/items/ItemsTable'
import PageHeader from '../widgets/main/PageHeader'
import TopHeader from '../widgets/main/TopHeader'
import MainButton from '../widgets/main/MainButton'
import ReserveTable from '../widgets/items/ReserveTable'
import ScrapTable from '../widgets/items/ScrapTable'

function ScrapPage() {
    return (
        <>
        <TopHeader />
        <div className='page'>
          <div className='user-page-header'>
            <PageHeader title='Selejt eszközök' />
            <div className='header-add-section'>
              <Link style={{textDecoration: 'none'}} to={{
                pathname: "/additem",
                state: {
                  getLocation: [],
                },
              }}>
                <MainButton name='Új eszköz' />
              </Link>
            </div>
          </div>
          <ScrapTable />
        </div>
      </>
    )
}

export default ScrapPage
