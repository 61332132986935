import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom"

async function loginUser(credentials) {
    return fetch('https://targyi-eszkoz.appsolution.hu:3001/authentication/signin/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }

function Login({ setToken }) {
  let history = useHistory()
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const [failure, setFailure] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
          email,
          password
        });
        console.log(token);
        if(token.message == 'success'){
           setToken(token);
           try {
            history.push("/items");
          } catch (error) {
            console.log(error);
          }
        }else{
          setFailure(true);
        }
      }


    return (
    <div className='login-page'>
     
    <div className="login-wrapper">
    {!failure ?  
      <div></div>
      :
      <Alert key={0} variant='danger'>
      Hibás email cím vagy Jelszó
      </Alert>}
      <h2>Bejelentkezés</h2>
      <br></br>
      <form onSubmit={handleSubmit}>
        <label>
          <input className='login-input' type="text" placeholder='Email' onChange={e => setUserName(e.target.value)}/>
        </label>
        <label>
          <input type="password" className='login-input' placeholder='Jelszó' onChange={e => setPassword(e.target.value)}/>
        </label>
        <div>
          <button className='login-button' type="submit">Bejelentkezés</button>
        </div>
      </form>
    </div>
    </div>
    )
}

export default Login
