import React from 'react'
import { BsChevronDoubleDown } from "react-icons/bs";
import { Link } from 'react-router-dom';

function UserWIdget() {
    const token = localStorage.getItem('token');
    const user = JSON.parse(token);

    const logout = () => {
        const token = localStorage.clear();
    };
    return (
        <div className='user-widget'>
            {user.data.name}
            <div className='user-widget-dropdown'>
                <span className='user-name'>{user.data.level}</span>
                <Link to='/login'>
                <a onClick={logout}>Kijelentkezés</a>
                </Link>
            </div>
        </div>
    )
}

export default UserWIdget
