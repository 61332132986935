import { IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid'
import React, { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import Moment from 'react-moment';
import Popup from 'reactjs-popup';
import QRCode from 'react-qr-code';
import qrImage from '../assets/qr.png';
import tt from '../assets/test.png';
import MainButton from '../main/MainButton';
import Stepper from '../main/Stepper';
import useAuth from '../../context/user-context';
import { MdSmartphone } from 'react-icons/md';



const rows = [
  { id: 1, as_id: 1, name: '', item: '', userId: '' },
];


function LockerTable() {
  const token = localStorage.getItem('token');
  const userToken = JSON.parse(token);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [text, setText] = useState(null);
  const { savedPage, setPageIndex } = useAuth();
  //asd
  const fetchItems = async (text) => {
    var url = "https://targyi-eszkoz.appsolution.hu:3001/locker";
    //  var url = "http://127.0.0.1:3002/items/admin/search/";
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      headers: { '500Token': userToken.token, 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data)
      });
  }

  useEffect(() => {
    console.log('start');
    fetchItems()
  }, [])

  const serachType = () => {
    const text = document.getElementById("searchbar").value;
    setText(text);
    fetchItems(text);
  };

  const handleDelete = (id) => {
    fetch("https://targyi-eszkoz.appsolution.hu:3001/locker/" + id, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      headers: { '500Token': userToken.token },
    }).then((val) => fetchItems());
  }



  const columns = [
    {
      field: 'id', headerName: 'ID', width: 160, renderCell: (params) => {
        return (
          <div className='item-id-cell'>
            <div className='qr' >
              <Popup trigger={<img width="30" height="30" src={qrImage} alt="Logo" />} modal>
                <div className='qr-box box'>
                  <QRCode value={params.row.uid} />
                </div>
              </Popup>
            </div>
          </div>
        )
      }
    },
    { field: 'name', headerName: 'Megnevezés', width: 300 },
    //<MainButton onClick={() => openInvoice(params.row.uuid)} name='PDF' />
    {
      field: 'action', headerName: 'Művelet', width: 150, renderCell: (params) => {
        return (
          <div className='action-buttons'>

            <IconButton aria-label="primary" variant="outlined" color="secondary">
              <DeleteIcon onClick={() => handleDelete(params.row.uid)} />
            </IconButton>
          </div>
        )
      }
    },
    { field: 'uid', headerName: '', hide: true },
  ];

  const stepPage = (val, full = false) => {
    if (data.length / 10 == 1) {
      return;
    }
    if (full) {
      if (val) {
        const end = Math.floor((data.length / 10));
        setPageIndex(end);
      } else {
        setPageIndex(0);
      }
      return;
    }
    if (val) {
      if (savedPage < Math.floor((data.length / 10))) {
        setPageIndex(savedPage + 1);
      }
    } else {
      if (savedPage - 1 >= 0) {
        setPageIndex(savedPage - 1);
      }
    }
  }

  return (
    <div>
      <div className='table-row'>
    <div></div>
        <Stepper length={!data ? 1 : data.length} onTap={stepPage} index={savedPage} />
      </div>
      <div className='user-table col-12'>
        <div style={{ height: 650, width: '100%' }}>
          <DataGrid
            page={savedPage}
            options={{
              initialPage: 5
            }}
            rows={!data ? rows : data} columns={columns} pageSize={10} disableSelectionOnClick hideFooter />
        </div>
      </div>
    </div>

  )
}

export default LockerTable
