import React from 'react'
import MainHeader from './MainHeader'
import MainNavbar from './MainNavbar'

function TopHeader() {
    return (
        <div className='stickyHeader'>
            <MainHeader/>
            <MainNavbar />
        </div>
    )
}

export default TopHeader
