import React from 'react'

function PageHeader({title}) {
    return (
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-flex align-items-center justify-content-between'>
                        <h4>{title}</h4>
                    </div>
                </div>
            </div>
    )
}

export default PageHeader
