import { Button } from 'react-bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'
import ItemsTable from '../widgets/items/ItemsTable'
import PageHeader from '../widgets/main/PageHeader'
import TopHeader from '../widgets/main/TopHeader'
import MainButton from '../widgets/main/MainButton'

function ItemsPage() {
  return (
    <>
      <TopHeader />
      <div className='page'>
        <div className='user-page-header'>
          <PageHeader title='Eszközök' />
          <div className='header-add-section'>
            <Link style={{textDecoration: 'none'}} to={{
              pathname: "/additem",
              state: {
                getLocation: [],
              },
            }}>
              <MainButton name='Új eszköz' />
            </Link>
          </div>
        </div>
        <ItemsTable />
      </div>
    </>
  )
}

export default ItemsPage
