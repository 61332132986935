import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AiFillFolderOpen,AiOutlineUser, AiFillFileText, AiFillDelete } from "react-icons/ai";
import { GiLockers  } from "react-icons/gi";
import { BsWindow  } from "react-icons/bs";

function MainNavbar() {
    const token = localStorage.getItem('token');
    const user = JSON.parse(token);
    console.log(user);
    return (
        <div className='main-navbar'>
            <Link to='/items' >
            <a><AiFillFolderOpen /><span className='navbar-item-title'>Eszközök</span></a>
            </Link>
            <Link to='/reserve' >
            <a><BsWindow /><span className='navbar-item-title'>Tartalék eszközök</span></a>
            </Link>
            <Link to='/scrap' >
            <a><AiFillDelete /><span className='navbar-item-title'>Selejt eszközök</span></a>
            </Link>
            <Link to='/locker' >
            <a><GiLockers /><span className='navbar-item-title'>Szekrények</span></a>
            </Link>
            {user.data.level == "SuperAdmin" ? 
            <Link to='/users'> 
            <a><AiOutlineUser /><span className='navbar-item-title'>Felhasználók</span></a>
            </Link> :
            <div></div>
            }
            <Link to='/query'>
            <a><AiFillFileText /><span className='navbar-item-title'>Lekérdezések</span></a>
            </Link>
        </div>
    )
}

export default MainNavbar
