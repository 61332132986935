import React from 'react'
import UserWIdget from './UserWIdget'


function MainHeader() {
    return (
        <div className='main-header col-sm-12'>
            <a href='#' className='logo-box'>
                <img src='https://targyi-eszkoz.appsolution.hu/api/public/logo3.png' width="100" />
            </a>

            <UserWIdget name='Báder Bence' />
        </div>
    )
}

export default MainHeader
