import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";



const AuthContext = createContext(
  {}
);

// Export the provider as we need to wrap the entire app with it
export function AuthProvider({
  children,
}) {
  const [user, setUser] = useState();
  const [savedPage, setpage] = useState(0);
  const [savedRPage, setRpage] = useState(0);
  const [savedSPage, setSpage] = useState(0);
  const [savedUPage, setUpage] = useState(0);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  useEffect(() => {
    setpage(0);
    setRpage(0);
    setSpage(0);
    setUpage(0);
  }, []);

  // Call the logout endpoint and then remove the user
  // from the state.
  function setPageIndex(val) {
    setpage(val);
  }
  function setRPageIndex(val) {
    setRpage(val);
  }
  function setSPageIndex(val) {
    setSpage(val);
  }
  function setUPageIndex(val) {
    setUpage(val);
  }
  const memoedValue = useMemo(
    () => ({
      setPageIndex,
      setRPageIndex,
      setSPageIndex,
      setUPageIndex,
      savedPage,
      savedRPage,
      savedSPage,
      savedUPage,
    }),
    [savedPage, savedRPage, savedSPage, savedUPage]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}