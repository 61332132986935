import { Alert, Button } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import PageHeader from '../../widgets/main/PageHeader'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Redirect } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom"
import TopHeader from '../../widgets/main/TopHeader';
import MainButton from '../../widgets/main/MainButton';

function AddUserPage() {
    const loc = useLocation();
    const { getLocation } = loc.state == undefined ? [] : loc.state;
    const token = localStorage.getItem('token');
    const userToken = JSON.parse(token);
    let history = useHistory()
    const selected = 0;
    const [succ, setSeucces] = useState(false);
    const [failure, setFailure] = useState(false);
    //const [user, setUser] = React.useState(userData);


    const [name, setName] = useState(getLocation.length == 0 ? '' : getLocation.name)
    const [email, setEmail] = useState(getLocation.length == 0 ? '' : getLocation.email)
    const [state, setState] = useState({});

    useEffect(() => {
        if (getLocation.length == 0) {
            setState(1);
        } else {
            console.log(getLocation.levelId);
            setState(getLocation.levelId);
        }

    }, [])

    const save = (event) => {
        event.preventDefault();
        let url;
        if (getLocation.length == 0) {

            url = 'https://targyi-eszkoz.appsolution.hu:3001/authentication/signup';
        } else {
            url = 'https://targyi-eszkoz.appsolution.hu:3001/users/' + getLocation.id;
        }
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            referrerPolicy: 'no-referrer',
            body:
                getLocation.length == 0 ?
                    JSON.stringify({
                        'name': name,
                        'email': email,
                        'levelId': state,
                        'password': 'AS2021',
                    }) :
                    JSON.stringify({
                        'name': name,
                        'email': email,
                        'levelId': state,
                    }),
            headers: { 'Content-Type': 'application/json', '500Token': userToken.token },
        }).then((res) => res.json()).then((data) => {
            console.log(data);
            if (data.message == 'success') {
                history.push("/users")
            }else{
                setFailure(true);
            }
        });
    }
    const handleChange = (event) => {
        const temp = event.target.value;
        setState(temp);
        console.log(temp);
    };



    return (
        <>
            <style type="text/css">
                {`
        .btn-flat {
          color: #a344ec;
          border: #a344ec 1px solid;
        }
    
        .btn-flat:hover {
            background-color: #a344ec;
            color: white;
          }
       
        `}
            </style>
            <TopHeader />
            <div className='page'>
                <PageHeader title={getLocation.length == 0 ? 'Felhasználó hozzáadása' : 'Felhasználó szerkesztése'} />
                {!failure ?
                    <div></div>
                    :
                    <Alert key={0} variant='danger'>
                        Az email cím már használatban van
                    </Alert>}
                <div className='edit-user-container box'>
                    <form id="form1" className='edit-user-form' onSubmit={save}>
                        <label className='input-label'>Név</label>
                        <input required onChange={event => setName(event.target.value)} value={name} className='edit-user-input' type='text' placeholder='Név'></input>
                        {0 != 0 ? <div></div> :
                            <>
                                <label className='input-label'>Email</label>
                                <input onChange={event => setEmail(event.target.value)} value={email} className='edit-user-input' type='text' placeholder='example@example.com'></input>
                            </>
                        }
                        <label required className='input-label'>Jogosultság</label>
                        <FormControl className='edit-user-input'>
                            <NativeSelect
                                value={state}
                                name="level"
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'level' }}>
                                <option value={1} key={1}>SuperAdmin</option>
                                <option value={2} key={2}>Editor</option>
                                <option value={3} key={3}>User</option>
                            </NativeSelect>
                        </FormControl>
                        <Button type="submit" form="form1" value="Submit" className='edit-user-button' variant="flat" size='xxl'>Mentés</Button>
                    </form>
                </div>

            </div>
        </>
    )
}

export default AddUserPage
