import React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineDoubleArrow } from "react-icons/md";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";


function Stepper({ onTap, length, index, text = 'eszköz' }) {
    return (
        <div className='table-row'>
            <BiArrowToLeft onClick={() => onTap(false, true)} className='stepper-button' size={20} color={'#cd22d9'} />
            <MdKeyboardArrowLeft onClick={() => onTap(false)} className='stepper-button' size={40} color={'#cd22d9'} />
            <div className='table-col'>
                <div>{(index * 10) + ' - ' + (index * 10 + 10) + ' ' + text}</div>
                {length / 10 == 1 ? '1. oldal / 1. oldal' : index + 1 + '. oldal' + ' / ' +   Math.floor((length / 10) +1  ) + '. oldal'}
            </div>
            <MdKeyboardArrowRight onClick={() => onTap(true)} className='stepper-button' size={40} color={'#cd22d9'} />
            <BiArrowToRight onClick={() => onTap(true, true)} className='stepper-button' size={20} color={'#cd22d9'} />
            <div>&nbsp;</div>
        </div>
    )
}

export default Stepper
