import React, { useState, useEffect } from 'react'
import PageHeader from '../widgets/main/PageHeader'
import TopHeader from '../widgets/main/TopHeader'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import MainButton from '../widgets/main/MainButton';

function QueryPage() {
    const [selectedYear, setYear] = useState(2019);
    const [type, setType] = useState(0);
    const [data, setData] = useState(null);
    const [users, setUsers] = useState(null);
    const [locations, setLocations] = useState(null);
    const [user, setUser] = useState(7);
    const [location, setLocation] = useState(303);
    const [deleted, setDeleted] = useState(false);

    const yearChange = (event) => {
        const temp = event.target.value;
        setYear(temp);
        console.log(temp);
    };

    const typeChange = (event) => {
        const temp = event.target.value;
        setType(temp);
        console.log(temp);
    };

    const userChange = (event) => {
        const temp = event.target.value;
        setUser(temp);
        console.log(temp);
    };

    const locationChange = (event) => {
        const temp = event.target.value;
        setLocation(temp);
        console.log(temp);
    };

    const setDeletedVal = () => {
        if (deleted) {
            setDeleted(false);
        } else {
            setDeleted(true);
        }
        console.log(deleted);
    }


    const fetchUsers = async () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token);
        fetch("https://targyi-eszkoz.appsolution.hu:3001/query/getdata", {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            referrerPolicy: 'no-referrer',
            headers: { '500Token': userToken.token, 'Content-Type': 'application/json' },
        })
            .then((res) => res.json())
            .then((dataT) => {
                console.log(dataT.data.users);
                setUsers(dataT.data.users);
                setLocations(dataT.data.locations);
            });
    }

    useEffect(() => {
        fetchUsers()
    }, [])


    const getQuery = () => {
        const token = localStorage.getItem('token');
        const userToken = JSON.parse(token)
        let url;
        var del = deleted ? 1 : 0;
        if (type == 0) {
            url = "https://targyi-eszkoz.appsolution.hu:3001/query/year/" + selectedYear + '/' + del;
            var win = window.open(url, '_blank');
            win.focus();
        } else if (type == 1) {
            url = "https://targyi-eszkoz.appsolution.hu:3001/query/user/" + user + '/' + del;
            var win = window.open(url, '_blank');
            win.focus();
        } else {
         //   url = "http://127.0.0.1:3002/query/location/" + encodeURIComponent(location) + '/' + del;
            url = "https://targyi-eszkoz.appsolution.hu:3001/query/location/" + encodeURIComponent(location) + '/' + del;
            var win = window.open(url, '_blank');
            win.focus();
        }
        console.log('asd', url);
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            referrerPolicy: 'no-referrer',
            headers: { '500Token': userToken.token },
        })
    }

    return (
        <div>
            <TopHeader />
            <div className='page'>
                <div className='user-page-header'>
                    <PageHeader title='Lekérdezések' />
                </div>
                <div className='query-container box'>
                    <div className='query-year-picker'>
                        <span className='query-year-title'>Lekérdezés típusa:</span>
                        <FormControl className='edit-user-inputxs'>
                            <NativeSelect
                                value={type}
                                name="type"
                                onChange={typeChange}
                                inputProps={{ 'aria-label': 'type' }}>
                                <option value={0}>Év</option>
                                <option value={1}>Felhasználó</option>
                                <option value={2}>Helyszín</option>
                            </NativeSelect>
                        </FormControl>
                    </div>
                    {type == 0 ?
                        <div className='query-year-picker'>
                            <span className='query-year-title'>Év kiválasztása:</span>
                            <FormControl className='edit-user-inputxs'>
                                <NativeSelect
                                    value={selectedYear}
                                    name="year"
                                    onChange={yearChange}
                                    inputProps={{ 'aria-label': 'year' }}>
                                    <option value={2019}>2019</option>
                                    <option value={2020}>2020</option>
                                    <option value={2021}>2021</option>
                                    <option value={2022}>2022</option>
                                </NativeSelect>
                            </FormControl>
                        </div> :
                        type == 1 ?
                            <div className='query-year-picker'>
                                <span className='query-year-title'>Felh. kiválasztása:</span>
                                <FormControl className='edit-user-inputxs'>
                                    <NativeSelect
                                        value={user}
                                        name="user"
                                        onChange={userChange}
                                        inputProps={{ 'aria-label': 'year' }}>
                                        {!users ? <option key='0'></option> : users.map((user) =>
                                            <option key={user.id} value={user.id}>{user.name}</option>)}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            :
                            <div className='query-year-picker'>
                                <span className='query-year-title'>Helyszín kiválasztása:</span>
                                <FormControl className='edit-user-inputxs'>
                                    <NativeSelect
                                        value={location}
                                        name="location"
                                        onChange={locationChange}
                                        inputProps={{ 'aria-label': 'year' }}>
                                        {!locations ? <option key='0'></option> : locations.map((loc) =>
                                            <option key={loc} value={loc}>{loc}</option>)}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                    }
                    <div className='query-button-box'>
                        <div>
                            <label><input type="checkbox" id="deleted" onChange={setDeletedVal} value={deleted} name="deleted"></input>
                                <span className='query-checkbox-text query-deleted'>Tartamazza a törölt eszközöket is</span></label>
                        </div>
                        <br></br>
                        <MainButton onClick={getQuery} name='Lekérdez' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QueryPage
//<Button onClick={getQuery} variant="outline-success">Lekérdez</Button>