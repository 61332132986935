import { IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid'
import React, { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import Moment from 'react-moment';
import Popup from 'reactjs-popup';
import QRCode from 'react-qr-code';
import qrImage from '../assets/qr.png';
import tt from '../assets/test.png';
import MainButton from '../main/MainButton';
import useAuth from '../../context/user-context';
import Stepper from '../main/Stepper';



const rows = [
    { id: 1, as_id: 1, name: '', item: '', userId: '' },
];

function ReserveTable() {
    const token = localStorage.getItem('token');
    const userToken = JSON.parse(token);
    const [data, setData] = useState(null);
    const [text, setText] = useState(null);
    const { savedRPage, setRPageIndex } = useAuth();




    const fetchItems = async (text) => {
        fetch("https://targyi-eszkoz.appsolution.hu:3001/items/admin/search/", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify({
                'text': text ? text : '',
                'reserve': 1,
                'scrap': 0,
            }),
            referrerPolicy: 'no-referrer',
            headers: { '500Token': userToken.token, 'Content-Type': 'application/json' },
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data)
            });
    }

    useEffect(() => {
        console.log('start');
        fetchItems()
    }, [])

    const serachType = () => {
        const text = document.getElementById("searchbar").value;
        setText(text);
        fetchItems(text);
    };

    const handleDelete = (id) => {
        fetch("https://targyi-eszkoz.appsolution.hu:3001/items/" + id, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            referrerPolicy: 'no-referrer',
            headers: { '500Token': userToken.token },
        }).then((val) => fetchItems());
    }

    const openInvoice = (id) => {
        fetch("https://targyi-eszkoz.appsolution.hu:3001/items/invoice/" + id, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            referrerPolicy: 'no-referrer',
            headers: { '500Token': userToken.token },
        })
            .then((res) => res.json())
            .then((val) => {
                const url = val.url;
                var win = window.open(url, '_blank');
                win.focus();
            });
    }


    const columns = [
        {
            field: 'as_id', headerName: 'AS ID', width: 160, renderCell: (params) => {
                console.log(params.row);
                const idd = params.row.as_id.toString();
                var temp;
                console.log(idd.length);
                switch (idd.length) {
                    case 1:
                        temp = 'AS000' + idd;
                        break;
                    case 2:
                        temp = 'AS00' + idd;
                        break;
                    case 3:
                        temp = 'AS0' + idd;
                        break;
                    default:
                        temp = 'AS' + idd;
                }
                return (
                    <div className='item-id-cell'>
                        <span>{temp}</span>
                        <div className='qr' >
                            <Popup trigger={<img width="30" height="30" src={qrImage} alt="Logo" />} modal>
                                <div className='qr-box box'>
                                    <QRCode value={params.row.uuid} />
                                </div>
                            </Popup>
                        </div>
                    </div>
                )
            }
        },
        { field: 'categoryName', headerName: 'Kategória', width: 250 },
        { field: 'name', headerName: 'Megnevezés', width: 300 },
        { field: 'account', headerName: 'Könyvelési azonosító', width: 220 },
        { field: 'userName', headerName: 'Felhasználó', width: 200 },
        { field: 'location', headerName: 'Hely', width: 200 },
        { field: 'item', headerName: 'Termék azonosító. 1', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'reserve', headerName: 'reserve', hide: true },
        { field: 'itemSec', headerName: 'Termék azonosító 2', hide: true },
        {
            field: 'dateOfPurchase', headerName: 'Vásárlás dátuma', hide: true, renderCell: (params) => {
                return (
                    <>
                        <Moment date={params.row.dateOfPurchase} format="YYYY-MM-DD" />
                    </>
                )
            }
        },
        { field: 'receipt', headerName: 'AS számla sorszáma', hide: true },
        //<MainButton onClick={() => openInvoice(params.row.uuid)} name='PDF' />
        { field: 'comment', headerName: 'Megjegyzés', hide: true },
        {
            field: 'invoice', headerName: 'Számla', width: 150, renderCell: (params) => {
                if (params.row.receiptPath) {
                    return (

                        <div className='action-buttons'>
                            <div className='action-btn' onClick={() => openInvoice(params.row.uuid)}>Gyorsnézet</div>
                        </div>
                    )
                }

            }
        },
        {
            field: 'action', headerName: 'Művelet', width: 150, renderCell: (params) => {
                return (
                    <div className='action-buttons'>
                        <Link style={{ textDecoration: 'none' }} to={{
                            pathname: "/additem",
                            state: {
                                getLocation: params.row,
                            },
                        }}>
                            <div className='action-btn'>Adatlap</div>
                        </Link>
                        <IconButton aria-label="primary" variant="outlined" color="secondary">
                            <DeleteIcon onClick={() => handleDelete(params.row.uuid)} />
                        </IconButton>
                    </div>
                )
            }
        },
        { field: 'uuid', headerName: '', hide: true },
        { field: 'categoryId', headerName: '', hide: true },
        { field: 'userId', headerName: 'Felhaználó', hide: true },
        { field: 'receiptPath', headerName: 'Fájlnév', hide: true },
    ];

    const stepPage = (val, full = false) => {
        if(data.length / 10 == 1){
            return;
        }
        if (full) {
            if (val) {
                const end = Math.floor((data.length / 10));
                setRPageIndex(end);
            } else {
                setRPageIndex(0);
            }
            return;
        }
        if (val) {
            if (savedRPage < Math.floor((data.length / 10))) {
                setRPageIndex(savedRPage + 1);
            }
        } else {
            if (savedRPage - 1 >= 0) {
                setRPageIndex(savedRPage - 1);
            }
        }
        console.log(savedRPage);
    }

    return (
        <div>
            <div className='table-row'>
                <input type="text" name="search" id='searchbar' className='searchbar' onChange={() => serachType()} placeholder="Keresés a tartalékban.."></input>
                <Stepper length={!data ? 1 : data.length} onTap={stepPage} index={savedRPage} />
            </div>
            <div className='user-table col-12'>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        page={savedRPage}
                        options={{
                            initialPage: 5
                        }}
                        rows={!data ? rows : data} columns={columns} pageSize={10} disableSelectionOnClick hideFooter />
                </div>
            </div>
        </div>

    )
}


export default ReserveTable
